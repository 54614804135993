@use 'styles/vapor' as vapor;

$min-tile-height: 220px;

.Tile {
  position: relative;
  border-radius: vapor.border-radius('extra-giant');
  box-shadow: vapor.shadow('drop-shadow-1');
  background-color: vapor.color('white');
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
  width: 100%;
  min-height: $min-tile-height;
  padding: vapor.spacing('base-semi-loose');

  @include vapor.respond-to('mid-mobile') {
    padding: vapor.spacing('base-loose');
  }

  &.loading {
    display: flex;
    background-color: vapor.color('white');
  }

  &.error {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: vapor.color('snow', 'dark-100');

    .errorContent {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}

.Info {
  white-space: nowrap;
}
